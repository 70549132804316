import React from "react";
import { useHistory } from "react-router";
import { assignmentStatusText, getFormattedTime, getStatusByUser, isApproved, SubmissionStatus, timeFormatter } from "../../Helpers";
import { useAppState } from "../../overmind";
import { Submission } from "../../../proto/qf/types_pb";
import ProgressBar, { Progress } from "../ProgressBar";
const SubmissionsTable = () => {
    const state = useAppState();
    const history = useHistory();
    const sortedAssignments = () => {
        const assignments = [];
        for (const courseID in state.assignments) {
            assignments.push(...state.assignments[courseID]);
        }
        assignments.sort((a, b) => {
            if (a.deadline && b.deadline) {
                return a.deadline.toDate().getTime() - b.deadline.toDate().getTime();
            }
            return 0;
        });
        return assignments;
    };
    const NewSubmissionsTable = () => {
        const table = [];
        sortedAssignments().forEach(assignment => {
            const courseID = assignment.CourseID;
            const submissions = state.submissions.ForAssignment(assignment);
            if (!submissions) {
                return;
            }
            const submission = submissions.find(sub => sub.AssignmentID === assignment.ID) ?? new Submission();
            const status = getStatusByUser(submission, state.self.ID);
            if (!isApproved(status) && assignment.deadline) {
                const deadline = timeFormatter(assignment.deadline);
                if (deadline.daysUntil > 3 && submission.score >= assignment.scoreLimit) {
                    deadline.className = "table-success";
                }
                if (!deadline.message) {
                    return;
                }
                const course = state.courses.find(c => c.ID === courseID);
                table.push(React.createElement("tr", { key: assignment.ID.toString(), className: `clickable-row ${deadline.className}`, onClick: () => history.push(`/course/${courseID}/lab/${assignment.ID}`) },
                    React.createElement("th", { scope: "row" }, course?.code),
                    React.createElement("td", null,
                        assignment.name,
                        assignment.isGroupLab ?
                            React.createElement("span", { className: "badge ml-2 float-right" },
                                React.createElement("i", { className: "fa fa-users", title: "Group Assignment" })) : null),
                    React.createElement("td", null,
                        React.createElement(ProgressBar, { courseID: courseID.toString(), submission: submission, type: Progress.OVERVIEW })),
                    React.createElement("td", null, getFormattedTime(assignment.deadline, true)),
                    React.createElement("td", null, deadline.message ? deadline.message : '--'),
                    React.createElement("td", { className: SubmissionStatus[status] }, assignmentStatusText(assignment, submission, status))));
            }
        });
        return table;
    };
    return (React.createElement("div", null,
        React.createElement("table", { className: "table rounded-lg table-bordered table-hover", id: "LandingPageTable" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { scope: "col" }, "Course"),
                    React.createElement("th", { scope: "col" }, "Assignment"),
                    React.createElement("th", { scope: "col" }, "Progress"),
                    React.createElement("th", { scope: "col" }, "Deadline"),
                    React.createElement("th", { scope: "col" }, "Due in"),
                    React.createElement("th", { scope: "col" }, "Status"))),
            React.createElement("tbody", null, NewSubmissionsTable()))));
};
export default SubmissionsTable;
