import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { hasReviews, isManuallyGraded } from '../Helpers';
import { useAppState, useActions } from '../overmind';
import CourseLinks from "./CourseLinks";
import LabResultTable from "./LabResultTable";
import ReviewResult from './ReviewResult';
const Lab = () => {
    const state = useAppState();
    const actions = useActions();
    const { id, lab } = useParams();
    const courseID = id;
    const assignmentID = lab ? BigInt(lab) : BigInt(-1);
    const location = useLocation();
    const isGroupLab = location.pathname.includes("group-lab");
    useEffect(() => {
        if (!state.isTeacher) {
            actions.setSelectedAssignmentID(Number(lab));
        }
    }, [lab]);
    const InternalLab = () => {
        let submission;
        let assignment;
        if (state.isTeacher) {
            submission = state.selectedSubmission;
            assignment = state.assignments[courseID].find(a => a.ID === submission?.AssignmentID) ?? null;
        }
        else {
            assignment = state.assignments[courseID]?.find(a => a.ID === assignmentID) ?? null;
            if (!assignment) {
                return React.createElement("div", null, "Assignment not found");
            }
            const submissions = state.submissions.ForAssignment(assignment) ?? null;
            if (!submissions) {
                return React.createElement("div", null, "No submissions found");
            }
            if (isGroupLab) {
                submission = submissions.find(s => s.groupID > 0n) ?? null;
            }
            else {
                submission = submissions.find(s => s.userID === state.self.ID && s.groupID === 0n) ?? null;
            }
        }
        if (assignment && submission) {
            const review = hasReviews(submission) ? submission.reviews : [];
            let buildLog = [];
            const buildLogRaw = submission.BuildInfo?.BuildLog;
            if (buildLogRaw) {
                buildLog = buildLogRaw.split("\n").map((x, i) => React.createElement("span", { key: i },
                    x,
                    React.createElement("br", null)));
            }
            return (React.createElement("div", { key: submission.ID.toString(), className: "mb-4" },
                React.createElement(LabResultTable, { submission: submission, assignment: assignment }),
                isManuallyGraded(assignment) && submission.released ? React.createElement(ReviewResult, { review: review[0] }) : null,
                React.createElement("div", { className: "card bg-light" },
                    React.createElement("code", { className: "card-body", style: { color: "#c7254e", wordBreak: "break-word" } }, buildLog))));
        }
        return (React.createElement("div", null, "No submission found"));
    };
    return (React.createElement("div", { className: state.isTeacher ? "" : "row" },
        React.createElement("div", { className: state.isTeacher ? "" : "col-md-9" },
            React.createElement(InternalLab, null)),
        state.isTeacher ? null : React.createElement(CourseLinks, null)));
};
export default Lab;
