import { Enrollment, Group } from "../../proto/qf/types_pb";
import { groupRepoLink, SubmissionSort, userRepoLink } from "../Helpers";
import { useActions, useAppState } from "../overmind";
export const generateSubmissionRows = (elements, generator) => {
    const state = useAppState();
    const course = state.courses.find(c => c.ID === state.activeCourse);
    const assignments = state.getAssignmentsMap(state.activeCourse);
    return elements.map(element => {
        return generateRow(element, assignments, state.submissionsForCourse, generator, state.individualSubmissionView, course, state.isCourseManuallyGraded);
    });
};
export const generateRow = (enrollment, assignments, submissions, generator, individual, course, withID) => {
    const row = [];
    const isEnrollment = enrollment instanceof Enrollment;
    const isGroup = enrollment instanceof Group;
    if (withID) {
        isEnrollment
            ? row.push({ value: enrollment.userID.toString() })
            : row.push({ value: enrollment.ID.toString() });
    }
    if (isEnrollment && enrollment.user) {
        row.push({ value: enrollment.user.Name, link: userRepoLink(enrollment.user, course) });
    }
    else if (isGroup) {
        row.push({ value: enrollment.name, link: groupRepoLink(enrollment, course) });
    }
    Object.entries(assignments)?.forEach(([assignmentID, isGroupLab]) => {
        let submission;
        if (isGroup && !isGroupLab) {
            return;
        }
        if (isGroup && isGroupLab) {
            submission = submissions.ForGroup(enrollment)?.find(s => s.AssignmentID.toString() === assignmentID);
        }
        if (isEnrollment) {
            if (isGroupLab && enrollment.groupID === 0n) {
                submission = submissions.ForUser(enrollment)?.find(s => s.AssignmentID.toString() === assignmentID);
            }
            else if (isGroupLab && !individual) {
                submission = submissions.ForGroup(enrollment)?.find(s => s.AssignmentID.toString() === assignmentID);
            }
            else {
                submission = submissions.ForUser(enrollment)?.find(s => s.AssignmentID.toString() === assignmentID);
            }
        }
        if (submission) {
            row.push(generator(submission, enrollment));
            return;
        }
        row.push("N/A");
    });
    return row;
};
export const generateAssignmentsHeader = (assignments, group) => {
    const isCourseManuallyGraded = useAppState((state) => state.isCourseManuallyGraded);
    const actions = useActions();
    const base = [
        { value: "Name", onClick: () => actions.setSubmissionSort(SubmissionSort.Name) }
    ];
    if (isCourseManuallyGraded) {
        base.unshift({ value: "ID", onClick: () => actions.setSubmissionSort(SubmissionSort.ID) });
    }
    for (const assignment of assignments) {
        if (group && assignment.isGroupLab) {
            base.push({ value: `${assignment.name} (g)`, onClick: () => actions.review.setAssignmentID(assignment.ID) });
        }
        if (!group) {
            base.push({ value: assignment.isGroupLab ? `${assignment.name} (g)` : assignment.name, onClick: () => actions.review.setAssignmentID(assignment.ID) });
        }
    }
    return base;
};
