import React from 'react';
import { useHistory } from 'react-router';
import { assignmentStatusText, getStatusByUser, isGroupSubmission, isValidSubmissionForAssignment } from "../../Helpers";
import ProgressBar, { Progress } from "../ProgressBar";
import SubmissionTypeIcon from './SubmissionTypeIcon';
const SubmissionRow = ({ submission, assignment, courseID, selfID }) => {
    const history = useHistory();
    const redirectTo = (submission) => {
        if (submission.groupID !== 0n) {
            history.push(`/course/${courseID}/group-lab/${submission.AssignmentID.toString()}`);
        }
        else {
            history.push(`/course/${courseID}/lab/${submission.AssignmentID.toString()}`);
        }
    };
    if (!isValidSubmissionForAssignment(submission, assignment)) {
        return null;
    }
    return (React.createElement("div", { key: submission.ID.toString(), className: "row clickable mb-1 py-2 align-items-center text-left", onClick: () => redirectTo(submission), role: "button" },
        React.createElement("div", { className: "col-8" },
            React.createElement(ProgressBar, { courseID: courseID, submission: submission, type: Progress.LAB })),
        React.createElement(SubmissionTypeIcon, { solo: !isGroupSubmission(submission) }),
        React.createElement("div", { className: "col-3" }, assignmentStatusText(assignment, submission, getStatusByUser(submission, selfID)))));
};
export default SubmissionRow;
