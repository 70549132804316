import React from "react";
import { getCourseID } from "../../Helpers";
import { useAppState } from "../../overmind";
import AssignmentCard from "./AssignmentCard";
const CourseLabs = () => {
    const state = useAppState();
    const courseID = getCourseID().toString();
    const assignments = state.assignments[courseID] || [];
    const selfID = state.self.ID;
    return (React.createElement("ul", { className: "list-group" }, assignments.map((assignment) => {
        const submissions = state.submissions.ForAssignment(assignment);
        return (React.createElement(AssignmentCard, { key: assignment.ID.toString(), assignment: assignment, submissions: submissions, courseID: courseID, selfID: selfID }));
    })));
};
export default CourseLabs;
