import React from "react";
import { Route, Switch, useHistory } from "react-router";
import { getCourseID } from "../Helpers";
import CourseLabs from "../components/student/CourseLabs";
import CourseLinks from "../components/CourseLinks";
import GroupPage from "./GroupPage";
import Lab from "../components/Lab";
import RedirectButton from "../components/RedirectButton";
import Alerts from "../components/alerts/Alerts";
const StudentPage = () => {
    const courseID = getCourseID();
    const history = useHistory();
    const root = `/course/${courseID}`;
    return (React.createElement("div", { className: "box" },
        React.createElement(RedirectButton, { to: root }),
        React.createElement(Alerts, null),
        React.createElement("div", { className: "row", hidden: history.location.pathname !== root },
            React.createElement("div", { className: "col-md-9" },
                React.createElement(CourseLabs, null)),
            React.createElement(CourseLinks, null)),
        React.createElement(Switch, null,
            React.createElement(Route, { path: "/course/:id/group", exact: true, component: GroupPage }),
            React.createElement(Route, { path: "/course/:id/lab/:lab", exact: true, component: Lab }),
            React.createElement(Route, { path: "/course/:id/group-lab/:lab", exact: true, component: Lab }))));
};
export default StudentPage;
