import React from 'react';
import { getFormattedTime } from "../../Helpers";
import SubmissionRow from './SubmissionRow';
const AssignmentCard = ({ assignment, submissions, courseID, selfID }) => {
    return (React.createElement("div", { key: assignment.ID.toString(), className: "card mb-4 shadow-sm" },
        React.createElement("div", { className: "card-header" },
            React.createElement("div", { className: "d-flex justify-content-between align-items-center" },
                React.createElement("div", { className: "d-flex align-items-center" },
                    React.createElement("h5", { className: "card-title mb-0" }, assignment.name),
                    assignment.isGroupLab && (React.createElement("span", { className: "badge badge-secondary ml-2 p-2" }, "Group"))),
                React.createElement("div", null,
                    React.createElement("i", { className: "fa fa-calendar mr-2" }),
                    " ",
                    getFormattedTime(assignment.deadline, true)))),
        React.createElement("div", { className: "card-body" }, submissions.map((submission) => (React.createElement(SubmissionRow, { key: submission.ID.toString(), submission: submission, assignment: assignment, courseID: courseID, selfID: selfID }))))));
};
export default AssignmentCard;
